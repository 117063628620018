.App {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    min-height: 100vh;
    background-color: #ffffff;
    color: #19191b;
    text-align: center;
}

.App.dark {
    background-color: #19191b;
    color: #ffffff;
}

.container {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 42rem;
    height: 100%;
    margin: 0 auto;
    padding-top: 4rem;
    flex: 1;
}

.profile-image {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    border: #e4e4e7;
    border-width: 2px;
    border-style: solid;
    image-rendering: -webkit-optimize-contrast;
}

.dark .profile-image {
    border: whitesmoke;
}

.experience-grid {
    display: grid;
    grid-template-columns: 8rem auto;
    justify-items: start;
    row-gap: 2rem;
    column-gap: 1rem;
}

.divider {
    border-top: 2px solid #262626;
    opacity: 0.1;
    width: 6rem;
    /* width: 0rem; */
    margin: 2.25rem 0;
}

.dark .divider {
    border-top: 2px solid whitesmoke;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@media (min-width: 1025px) {
    .container {
        width: 90%;
        max-width: 42rem;
        height: 100%;
        margin: 4 auto;
    }

    .experience-grid {
        display: grid;
        grid-template-columns: 8rem auto;
        justify-items: start;
        row-gap: 1.75rem;
        column-gap: 5rem;
    }
}
