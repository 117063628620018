@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Inter', sans-serif;
    /* font-family: 'Montserrat', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --color-primary: #0B7189;
    --color-secondary: rgb(11, 113, 137, 0.20);
    /* --color-primary-light: #a7d4ff; */
    --color-primary-light: #73ecc8;
    --color-secondary-light: rgb(181, 234, 255, 0.25);
}
